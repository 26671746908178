<script setup lang="ts">
const psElement = ref<HTMLElement | null>(null)
const scrollTop = ref(0)

onMounted(() => {
  psElement.value = document.querySelector('.ps')
  
  if (psElement.value) {
    psElement.value.addEventListener('ps-scroll-y', (e: Event) => {
      const target = e.target as HTMLElement
      scrollTop.value = target.scrollTop
    })
  }
})

const scrollToTop = () => {
  if (!psElement.value) return
  psElement.value.scrollTop = 0
}
</script>

<template>
  <VScaleTransition
    style="transform-origin: center;"
    class="scroll-to-top d-print-none"
  >
    <VBtn
      v-show="scrollTop > 200"
      icon
      density="comfortable"
      @click="scrollToTop"
    >
      <VIcon
        size="22"
        icon="tabler-arrow-up"
      />
    </VBtn>
  </VScaleTransition>
</template>

<style lang="scss">
.scroll-to-top {
  position: fixed !important;

  // To keep button on top of v-layout. E.g. Email app
  z-index: 999;
  inset-block-end: 5%;
  inset-inline-end: 25px;
}
</style>
