<script lang="ts" setup>
import ScrollToTop from '@core/components/ScrollToTop.vue'
import { switchToVerticalNavOnLtOverlayNavBreakpoint } from '@layouts/utils'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

const DefaultLayoutWithVerticalNav = defineAsyncComponent(() => import('./components/DefaultLayoutWithVerticalNav.vue'))

// ℹ️ This will switch to vertical nav when define breakpoint is reached when in horizontal nav layout
// Remove below composable usage if you are not using horizontal nav layout in your app
switchToVerticalNavOnLtOverlayNavBreakpoint()

const { layoutAttrs, injectSkinClasses } = useSkins()

injectSkinClasses()

// SECTION: Loading Indicator
const isFallbackStateActive = ref(false)
const refLoadingIndicator = ref<any>(null)

// watching if the fallback state is active and the refLoadingIndicator component is available
watch([isFallbackStateActive, refLoadingIndicator], () => {
  if (isFallbackStateActive.value && refLoadingIndicator.value)
    refLoadingIndicator.value.fallbackHandle()

  if (!isFallbackStateActive.value && refLoadingIndicator.value)
    refLoadingIndicator.value.resolveHandle()
}, { immediate: true })

// !SECTION
</script>

<template>
  <div class="ps-wrapper">
    <PerfectScrollbar class="layout-scrollbar">
      <Component
        v-bind="layoutAttrs"
        :is="DefaultLayoutWithVerticalNav"
      >
        <AppLoadingIndicator ref="refLoadingIndicator" />

        <RouterView v-slot="{ Component }">
          <Suspense
            :timeout="0"
            @fallback="isFallbackStateActive = true"
            @resolve="isFallbackStateActive = false"
          >
            <Component :is="Component" />
          </Suspense>
        </RouterView>
      </Component>
      <ScrollToTop />
    </PerfectScrollbar>
  </div>
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@layouts/styles/default-layout";

.ps-wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout-scrollbar {
  height: 100%;
  flex: 1;
  
  > .ps {
    height: 100%;
  }
}

// Only for Navigation Drawer
.v-navigation-drawer__scrim {
  position: fixed !important;
  inset: 0;
  z-index: 5;
}

// Dialog Overlay with higher z-index
.v-overlay__scrim {
  position: fixed !important;
  inset: 0;
  z-index: 203; // Higher than Navigation Drawer but lower than Dialog
}

// Dialog itself needs highest z-index
.v-overlay__content {
  z-index: 204;
}
</style>
