import { defineStore } from 'pinia'
import type { State } from './types'
import { getCookie, setCookie } from '@/utils'
import { entryBroadcastMessagesList } from '@/api'

export const useBroadcastMessageStore = defineStore('BroadcastMessageStore', {
  state: (): State => ({
    broadcastMessages: [],
  }),
  getters: {
    broadcasts: state => {
      return state.broadcastMessages
    },
  },
  actions: {
    async fetchBroadcastMessages() {
      return entryBroadcastMessagesList().then((response: any) => {
        this.broadcastMessages = response.filter((message: any) => {
          return getCookie(`dismissed-broadcast-message-${message.id}`) === ''
        })

        return true
      }).catch(err => {
        console.log('Could not fetch broadcast messages due to the following error: ', err)

        return true
      })
    },
    removeMessage(messageId: any) {
      const removeIndex = this.broadcastMessages.map(item => item.id).indexOf(messageId)
      if (removeIndex !== -1) {
        setCookie(`dismissed-broadcast-message-${messageId}`, 'dismissed', 30)
        this.broadcastMessages.splice(removeIndex, 1)
      }
    },
  },
})
