import { defineStore } from 'pinia'
import type { State } from './types'
import { useBroadcastMessageStore } from '@/store/broadcast-message-store/useBroadcastMessageStore'
import {
  OpenAPI,
} from '@/api'
import type { CreateJwtTokenViaOauthRequest, CustomizedTokenObtainPairRequest, DashboardUser, PasswordChangeRequest, PatchedEmailResetRequestRequest, UserManagementTokenVerifyCreateData, Profile, PatchedProfileRequest, PasswordResetRequestRequest, PasswordResetConfirmRequest, UserManagementTokenCreateResponse } from '@/api'
import { 
  configRetrieve,
  userManagementOauthTokenCreate,
  userManagementPasswordResetConfirmCreate,
  userManagementPasswordResetRequestCreate,
  userManagementPasswordRulesRetrieve,
  userManagementProfileChangeEmailRequestPartialUpdate,
  userManagementProfileChangePasswordUpdate,
  userManagementProfilePartialUpdate,
  userManagementProfileRetrieve,
  userManagementTokenCreate,
  userManagementTokenRefreshCreate,
  userManagementTokenVerifyCreate, 
} from '@/api'

export const useAuthStore = defineStore('AuthStore', {
  state: (): State => ({
    accessToken: '',
    refreshToken: '',
    authInterval: '',
    broadCastInterval: '',
    appVersionInterval: '',
    newerFrontendVersionAvailable: false,
    allowUsernamePasswordLogin: false,
    oAuthInstances: [],
    features: [],
    user: undefined,
    userProfileData: undefined,
    router: [],
  }),
  getters: {
    isSameUser: state => {
      return (userData: DashboardUser) => state.user && (state.user.email === userData?.email)
    },
    OAuthProviders: state => {
      return state.oAuthInstances
    },
    getUser: state => {
      return state.user
    },
    getUserPermissionSet: state => {
      return new Set(state.user ? state.user.permissions : [])
    },
    getUserProfile: state => {
      return state.userProfileData as Profile
    },
    isAdmin: state => {
      return state.user?.is_superuser
    },
    isStaff: state => {
      return state.user?.is_staff
    },
  },
  actions: {
    hasPermission(permission: string) {
      return this.hasAllPermissions([permission])
    },
    featuresPermitted(permission: string) {
      return this.features?.includes(permission)
    },
    hasAnyPermission(permissions: string[]) {
      return this.user?.is_superuser || permissions.some(permission => this.getUserPermissionSet.has(permission))
    },
    hasAllPermissions(permissions: string[]) {
      return this.user?.is_superuser || permissions.every(permission => this.getUserPermissionSet.has(permission))
    },
    async isUserLoggedIn() {
      if (this.accessToken) {
        if (!this.user || !this.features || !this.user.permissions || this.user.permissions.length === 0)
          await this.getUserPermissions()
        
        return true
      }
      
      const refreshToken = localStorage.getItem('refreshToken')
      const accessToken = localStorage.getItem('accessToken')
      
      if (refreshToken && localStorage.getItem('userPermissions')) {
        this.refreshToken = JSON.parse(refreshToken)
        this.user = JSON.parse(localStorage.getItem('userPermissions') || '{}')
        this.userProfileData = JSON.parse(localStorage.getItem('userProfile') || '{}')
        this.features = JSON.parse(localStorage.getItem('userFeatures') || '{}')
        
        return await this.isTokenValid(this.refreshToken)
      } else if (accessToken && localStorage.getItem('userPermissions')) {
        this.accessToken = JSON.parse(accessToken)
        this.user = JSON.parse(localStorage.getItem('userPermissions') || '{}')
        this.userProfileData = JSON.parse(localStorage.getItem('userProfile') || '{}')
        this.features = JSON.parse(localStorage.getItem('userFeatures') || '{}')

        return await this.isTokenValid(this.accessToken)
      }
      
      const broadcastMessageStore = useBroadcastMessageStore()

      await broadcastMessageStore.fetchBroadcastMessages()
      this.setDefaultIntervals()
      
      return false
    },
    async isTokenValid(token: string | null | undefined) {
      try {
        if (process.env && ['development', 'test'].includes(process.env.VUE_APP_ENV as string) && process.env.VUE_APP_FIELD_SERVICE_API_URL) {
          OpenAPI.BASE = process.env.VUE_APP_FIELD_SERVICE_API_URL
        }

        return userManagementTokenVerifyCreate(<UserManagementTokenVerifyCreateData>{ requestBody: { token } }).then((_response: any) => {
          this.refreshToken = JSON.parse(localStorage.getItem('refreshToken') || '{}')
          this.getPersistentToken(<string> this.refreshToken).then(async _res => {
            await this.getUserPermissions()

            const broadcastMessageStore = useBroadcastMessageStore()

            await broadcastMessageStore.fetchBroadcastMessages()
            this.setDefaultIntervals()
            this.authIntervalSet(<string> this.refreshToken)

            return true
          }).catch(async err => {
            console.log('refresh token error: ', err)
            await this.logout()

            const broadcastMessageStore = useBroadcastMessageStore()

            await broadcastMessageStore.fetchBroadcastMessages()
            this.setDefaultIntervals()
            await this.router.push({ name: 'login' })
          })

          return true
        }).catch(async () => {
          await this.logout()

          const broadcastMessageStore = useBroadcastMessageStore()

          await broadcastMessageStore.fetchBroadcastMessages()
          this.setDefaultIntervals()

          return false
        })
      }
      catch (_err) {
        return false
      }
    },
    async logout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userPermissions')
      localStorage.removeItem('userProfile')
      localStorage.removeItem('userFeatures')
      this.authIntervalClear()
      this.accessToken = ''
      this.refreshToken = ''
      this.user = undefined
      this.features = []
      this.userProfileData = undefined
      OpenAPI.TOKEN = ''
    },
    authIntervalClear() {
      clearInterval(this.authInterval)
    },
    async authIntervalSet(refresh: string) {
      this.authInterval = setInterval(() => {
        this.getPersistentToken(refresh).then(async _res => {
          await this.getUserPermissions()

          return true
        }).catch(async err => {
          console.log('refresh token error: ', err)

          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          this.authIntervalClear()

          const broadcastMessageStore = useBroadcastMessageStore()

          await broadcastMessageStore.fetchBroadcastMessages()
          await this.router.push({ name: 'error?errorIcon=error-not-authorized' })
        })
      }, 240000)
    },
    setDefaultIntervals() {
      this.broadcastMessageIntervalSet()
      this.appVersionIntervalSet()
    },
    broadcastMessageIntervalSet() {
      this.broadCastInterval = setInterval(async () => {
        const broadcastMessageStore = useBroadcastMessageStore()

        await broadcastMessageStore.fetchBroadcastMessages()
      }, 240000)
    },
    appVersionIntervalSet() {
      this.appVersionInterval = setInterval(async () => {
        const versionRes = await fetch(`${window.location.origin}/version.json`)
        const { build_number } = await versionRes.json()
        if (import.meta.env.VITE_BUILD_NUMBER && build_number && build_number !== import.meta.env.VITE_BUILD_NUMBER)
          this.newerFrontendVersionAvailable = true
      }, 600000)
    },
    async getOAuthToken(params: CreateJwtTokenViaOauthRequest) {
      return userManagementOauthTokenCreate({ requestBody: params }).then(async (response: any) => {
        const { refresh } = response

        localStorage.setItem('refreshToken', JSON.stringify(refresh))
        this.refreshToken = refresh
        this.authIntervalSet(refresh)

        return this.getPersistentToken(refresh).then(async _res => {
          await this.getUserPermissions()

          return true
        }).catch(async err => {
          console.log('refresh token error: ', err)

          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          this.authIntervalClear()

          this.router.push({ name: 'login' })
        })
      }).catch((err: any) => {
        throw err
      })
    },
    async getPersistentToken(refresh: string) {
      return userManagementTokenRefreshCreate({ requestBody: { refresh } }).then((response: any) => {
        const { access } = response

        localStorage.setItem('accessToken', JSON.stringify(access))
        this.accessToken = access
        OpenAPI.TOKEN = access
      }).catch((err: any) => {
        throw err
      })
    },
    async getAccessToken(params: CustomizedTokenObtainPairRequest) {
      return userManagementTokenCreate({ requestBody: params }).then(async (response: UserManagementTokenCreateResponse) => {
        const { access, refresh } = response
        
        localStorage.setItem('accessToken', JSON.stringify(access))
        localStorage.setItem('refreshToken', JSON.stringify(refresh))
        
        this.refreshToken = refresh
        this.authIntervalSet(refresh)

        return this.getPersistentToken(refresh).then(async () => {
          const broadcastMessageStore = useBroadcastMessageStore()

          await broadcastMessageStore.fetchBroadcastMessages()
          await this.getUserPermissions()
          
          return true
        }).catch(async err => {
          console.log('refresh token error: ', err)
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('accessToken')

          this.authIntervalClear()

          this.router.push({ name: 'errors?errorIcon=error-not-authorized' })
        })
      })
    },
    async resetForgotPassword(data: PasswordResetRequestRequest) {
      return userManagementPasswordResetRequestCreate({ requestBody: data }).then(async () => {
        return true
      }).catch((err: any) => {
        throw err
      })
    },
    async confirmResetForgotPassword(token: string, uidb64: string, new_password: PasswordResetConfirmRequest) {
      return userManagementPasswordResetConfirmCreate({ requestBody: new_password, token, uidb64 }).then(async () => {
        return true
      }).catch((err: any) => {
        throw err
      })
    },
    async getUserPermissions() {
      return configRetrieve().then(async (response: any) => {
        const { user, features, allow_password_login, oauth_configurations } = response

        this.user = user
        this.features = features
        this.allowUsernamePasswordLogin = allow_password_login
        this.oAuthInstances = oauth_configurations
        localStorage.setItem('userPermissions', JSON.stringify(user))
        localStorage.setItem('userFeatures', JSON.stringify(features))

        return true
      }).catch((err: { body: any }) => {
        console.log('could not get user permissions', err.body)
      })
    },
    async getUserProfileData() {
      return userManagementProfileRetrieve().then(async (response: any) => {
        this.userProfileData = response
        localStorage.setItem('userProfile', JSON.stringify(response))

        return true
      }).catch((err: any) => {
        throw err
      })
    },
    async profileUpdate(data: PatchedProfileRequest) {
      return userManagementProfilePartialUpdate({ requestBody: data }).then(async (response: any) => {
        this.userProfileData = response

        if (this.user) {
          this.user.first_name = response.first_name
          this.user.last_name = response.last_name
        }

        return true
      }).catch((err: any) => {
        throw err
      })
    },
    async changePassword(data: PasswordChangeRequest) {
      return userManagementProfileChangePasswordUpdate({ requestBody: data }).then(async () => {
        return true
      }).catch((err: any) => {
        throw err
      })
    },
    async changeEmail(data: PatchedEmailResetRequestRequest) {
      return userManagementProfileChangeEmailRequestPartialUpdate({ requestBody: data }).then(async () => {
        return true
      }).catch((err: any) => {
        throw err
      })
    },
    async getPasswordRules() {
      return userManagementPasswordRulesRetrieve().then(async (response: any) => {
        return response
      }).catch((err: any) => {
        throw err
      })
    },
  },
})
