export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '$all',
    component: () => import('/app/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "requiresAuth": false,
      "redirectIfLoggedIn": true,
      "DontRedirectToLogin": true
    }
  },
  {
    path: '/api',
    /* internal name: 'api' */
    /* no component */
    children: [
      {
        path: 'public',
        name: 'api-public',
        component: () => import('/app/src/pages/api/public.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import('/app/src/pages/confirm-email.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "DontRedirectToLogin": true
    }
  },
  {
    path: '/errors',
    name: 'errors',
    component: () => import('/app/src/pages/errors.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "requiresAuth": false,
      "redirectIfLoggedIn": true,
      "DontRedirectToLogin": true
    }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('/app/src/pages/feedback.vue'),
    /* no children */
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/app/src/pages/forgot-password.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true,
      "DontRedirectToLogin": true
    }
  },
  {
    path: '/knowledge-base',
    name: 'knowledge-base',
    component: () => import('/app/src/pages/knowledge-base.vue'),
    /* no children */
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import('/app/src/pages/locations.vue'),
    /* no children */
    meta: {
      "action": "read",
      "subject": "Auth",
      "requiresPermission": "field_service.view_location",
      "requiresFeature": "entry-locations"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/app/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true,
      "DontRedirectToLogin": true
    }
  },
  {
    path: '/oauth',
    /* internal name: 'oauth' */
    /* no component */
    children: [
      {
        path: ':authProviderName',
        /* internal name: 'oauth:auth-provider-name' */
        /* no component */
        children: [
          {
            path: 'callback',
            name: 'oauth:auth-provider-name-callback',
            component: () => import('/app/src/pages/oauth/:authProviderName/callback.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('/app/src/pages/reset-password.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true,
      "DontRedirectToLogin": true
    }
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: () => import('/app/src/pages/sessions.vue'),
    /* no children */
    meta: {
      "action": "read",
      "subject": "Auth",
      "requiresPermission": "field_service.view_session",
      "requiresFeature": "entry-sessions"
    }
  },
  {
    path: '/set-password',
    name: 'set-password',
    component: () => import('/app/src/pages/set-password.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true,
      "DontRedirectToLogin": true
    }
  },
  {
    path: '/settings',
    /* internal name: 'settings' */
    /* no component */
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import('/app/src/pages/settings/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/technicians',
    name: 'technicians',
    component: () => import('/app/src/pages/technicians.vue'),
    /* no children */
    meta: {
      "action": "read",
      "subject": "Auth",
      "requiresPermission": "field_service.view_worker",
      "requiresFeature": "entry-workers"
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('/app/src/pages/users.vue'),
    /* no children */
    meta: {
      "action": "read",
      "subject": "Auth",
      "requiresPermission": "user_management.view_user",
      "requiresFeature": "user-management"
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

