import { deepMerge } from '@antfu/utils'

// @ts-ignore
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components/VBtn'
import { de, en } from 'vuetify/locale'
import defaults from './defaults'
import { icons } from './icons'
import { staticPrimaryColor, staticPrimaryDarkenColor, themes } from './theme'
import { themeConfig } from '@themeConfig'
import { getUserLocale } from '@/utils'

// Styles
import { cookieRef } from '@/@layouts/stores/config'
import '@core/scss/template/libs/vuetify/index.scss'
import 'vuetify/styles'
import { resolveVuetifyTheme } from '@/@core/utils/vuetify'

const cookieThemeValues = {
  defaultTheme: resolveVuetifyTheme(themeConfig.app.theme),
  themes: {
    light: {
      colors: {
        'primary': cookieRef('lightThemePrimaryColor', staticPrimaryColor).value,
        'primary-darken-1': cookieRef('lightThemePrimaryDarkenColor', staticPrimaryDarkenColor).value,
      },
    },
    dark: {
      colors: {
        'primary': cookieRef('darkThemePrimaryColor', staticPrimaryColor).value,
        'primary-darken-1': cookieRef('darkThemePrimaryDarkenColor', staticPrimaryDarkenColor).value,
      },
    },
  },
}

const optionTheme = deepMerge({ themes }, cookieThemeValues)

const userLanguage = navigator.language ? getUserLocale() : 'en'

export default createVuetify({
  aliases: {
    IconBtn: VBtn,
  },
  locale: {
    locale: userLanguage,
    fallback: 'en',
    messages: { en, de },
  },
  defaults,
  icons,
  theme: optionTheme,
})
