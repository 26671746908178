import axios from 'axios'
import axiosRetry from 'axios-retry'
import * as Sentry from '@sentry/vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

export const exponentialBackoff = (retryCount: number) => {
  const random_number_milliseconds = Math.floor(Math.random() * 1000)
  const backoffTimes = [0, 1000, 2000, 4000, 8000, 16000]

  return (backoffTimes[retryCount] ?? 0) + random_number_milliseconds
}

const redirectToError = (error: Error) => {
  if (process.env.NODE_ENV === 'test')
    throw error
  else
    window.location.href = '/errors?errorIcon=error-under-maintenance'
}

axiosRetry(axiosIns, {
  retries: 5,
  retryDelay: retryCount => exponentialBackoff(retryCount),
  onRetry(retryCount, error, requestConfig) {
    Sentry.captureMessage(`Retrying request ${requestConfig.url} ${retryCount} times. Failed with error: ${error.message}`)
  },
  onMaxRetryTimesExceeded: (error, retryCount) => {
    Sentry.captureException(`Request failed after ${retryCount} retries. Failed with error: ${error.message}`)
    redirectToError(error)
  },
  validateResponse: response => {
    return response.status >= 200 && response.status < 500
  },
})

// // ℹ️ Add request interceptor to send the authorization header on each subsequent request after login
// axiosIns.interceptors.request.use(config => {
//   // Retrieve token from localStorage
//   const token = localStorage.getItem('accessToken')

//   // If token is found
//   if (token) {
//     // Get request headers and if headers is undefined assign blank object
//     config.headers = config.headers || {}

//     // Set authorization header
//     // ℹ️ JSON.parse will convert token to string
//     config.headers.Authorization = token ? `Bearer ${JSON.parse(token)}` : ''
//   }

//   // Return modified config
//   return config
// })

// // ℹ️ Add response interceptor to handle 401 response
// axiosIns.interceptors.response.use(response => {
//   return response
// }, error => {
//   // Handle error
//   if (error.response.status === 401) {
//     // ℹ️ Logout user and redirect to login page
//     // Remove "userData" from localStorage
//     localStorage.removeItem('userData')

//     // Remove "accessToken" from localStorage
//     localStorage.removeItem('accessToken')
//     localStorage.removeItem('userAbilities')

//     // If 401 response returned from api
//     router.push('/login')
//   }
//   else {
//     return Promise.reject(error)
//   }
// })

export default axiosIns
